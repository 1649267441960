import { createFeature, createReducer, on } from '@ngrx/store';
import { RecaptchaActions } from './recaptcha.actions';

export interface RecaptchaState {
  errorMessage: string;
  errorCode: string;
  token: string;
  action: string;
  verified: boolean;
}

export const initialState: RecaptchaState = {
  errorCode: null,
  errorMessage: null,
  token: null,
  action: null,
  verified: false
};

export const recaptchaFeature = createFeature({
  name: 'recaptchaState',
  reducer: createReducer(
    initialState,
    on(RecaptchaActions.doAction, () => ({ ...initialState })),
    on(RecaptchaActions.sendRecaptcha, (state, { token, action }) => ({ ...state, token, action })),
    on(RecaptchaActions.sendRecaptchaSuccess, (state, { verified }) => ({ ...state, verified })),
    on(RecaptchaActions.sendRecaptchaFailure, (state, { errorCode, errorMessage }) => ({ ...state, errorCode, errorMessage }))
  )
});

const { name, reducer, ...selectors } = recaptchaFeature;
export const fromRecaptcha = selectors;
