import { createActionGroup, props } from '@ngrx/store';

const Recaptcha = createActionGroup({
  source: 'Recaptcha',
  events: {
    doAction: props<{ action: string }>(),
    sendRecaptcha: props<{ token: string; action: string }>()
  }
});

const RecaptchaApi = createActionGroup({
  source: 'Recaptcha/API',
  events: {
    doActionFailure: props<{ errorCode: string; errorMessage: string }>(),
    sendRecaptchaSuccess: props<{ verified: boolean }>(),
    sendRecaptchaFailure: props<{ errorCode: string; errorMessage: string }>()
  }
});

export const RecaptchaActions = {
  ...Recaptcha,
  ...RecaptchaApi
};
